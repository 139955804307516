import request from '@/utils/requestV2'
const qs = require('qs')

// 获取图例
export function getIcon (data) {
  return request({
    url: '/ooh-draw/svg/geticon',
    method: 'post',
    data: qs.stringify(data)
  })
}
