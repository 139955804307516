<template>
  <div class="station-new-tab">
    <p class="text-right">
      <a class="m-r-10 station-new-href" :class="showType===1? 'station-new-href-active':''" @click="handleChangeType(1)">
        <Icon type="ios-list-box-outline" size="24" />
      </a>
      <a class="station-new-href" :class="showType===2? 'station-new-href-active':''" @click="handleChangeType(2)">
        <Icon type="ios-pie-outline" size="24" />
      </a>
    </p>

    <template v-if="showType===1">
      <Row :gutter="8" class="m-t-5 m-b-10">
        <i-col span="8" v-for="(item,i) in businessData" :key="i" class="m-b-10">
          <div class="station-new-portray-card" :class="selectedBunisess===item.id?'station-new-portray-card-active':''" style="padding: 10px; cursor: pointer;" @click="handleClickBusiness(item)">
            <p class="text-14 text-darkgray m-b-2">{{item.name}}</p>
            <Row>
              <i-col span="16" class="text-16"><b>{{item.number}}</b></i-col>
              <i-col span="8" class="text-right text-gray" style="padding-top: 4px;">个</i-col>
            </Row>
            <p class="text-right">
              <span class="text-gray">密度</span>
              <span :class="getCompareValueColor(item.compareValue)"> {{ item.compareValue }} </span>
              <span class="text-gray">于本市均值</span>
            </p>
          </div>
        </i-col>
      </Row>
    </template>

    <template v-else>
      <Row>
        <i-col span="8">
          <div v-for="(item,i) in businessData" :key="i" class="station-chart-legend" :class="selectedBunisess===item.id?'station-chart-legend-active':''" @click="handleClickBusiness(item)">
            <div class="station-chart-legend-color" :style="{'background-color': reportColors[i % 9]}"></div>
            <div class="inline">{{item.name}}</div>
            <div class="right inline">{{ item.number }}个</div>
          </div>
        </i-col>
        <i-col span="16">
          <div style="width:100%;height:250px;" id="portrayAssortedChart_order"></div>
        </i-col>
      </Row>
    </template>

    <template v-if="industryData.length>0">
      <p class="text-14 text-darkgray">{{ selectedBunisessName }} 概况</p>
      <Divider dashed style="margin: 5px 0;"/>
      <Row class="m-b-10" :gutter="8">
        <i-col span="8" v-for="(item,i) in industryData" :key="i">
          <div  class="m-b-5 station-new-portray-card">
            <p class="m-b-5">{{ item.value }} {{ item.unit }}</p>
            <p>{{ item.key }}</p>
          </div>
        </i-col>
      </Row>
    </template>

    <p class="text-14 text-darkgray">商家信息</p>
    <Divider dashed style="margin: 5px 0;"/>
    <Table :columns="secondColumns" :data="businessDetails" stripe size="small"></Table>
    <div v-show="showArray.length>currentPageSize" class="paging_style">
        <Page size="small" :total="showArray.length" :page-size="currentPageSize" :current="currentPageNumber" @on-change="handlePageChange"></Page>
    </div>

  </div>
</template>

<script>
import * as echarts from 'echarts'
import { getStorage } from '@/utils/storage'
import { countBusinessNumber, listBusinessDetail, getIndustryData } from '@/api/dm/sun'

export default {
  data () {
    return ({
      showType: 1,
      selectStationId: null,
      businessData: [],
      industryData: [],
      selectedBunisess: null,
      selectedBunisessName: '',

      dataSourcePie: null,
      reportColors: [
        '#5470c6',
        '#91cc75',
        '#fac858',
        '#ee6666',
        '#73c0de',
        '#3ba277',
        '#fc8452',
        '#9a60b4',
        '#ea7ccc'
      ],
      secondColumns: [
        { title: '商家名称', key: 'businessName' },
        { title: '距离(米)', key: 'distance', width: 120 }
      ],
      businessDetails: [],
      showArray: [],
      currentPageSize: 10,
      currentPageNumber: 1
    })
  },
  methods: {
    ininBaseData (svgStationId) {
      this.selectStationId = svgStationId
      const queryModel = {
        industryType: 1, // 商业类型(1.配套;2:业态;)
        stationIds: svgStationId
      }

      countBusinessNumber(queryModel).then(res => {
        this.businessData = res
        if (res.length > 0) {
          this.handleClickBusiness(res[0])
        }
      })
    },
    handleChangeType (val) {
      this.showType = val
      if (val === 2) {
        const seriesData = this.businessData.map(x => {
          return {
            name: x.name,
            value: x.number
          }
        })

        const business = this.businessData.find(x => x.id === this.selectedBunisess)
        this.initCharts(seriesData, business.name, business.rate)
      }
    },
    handleClickBusiness (param) {
      this.selectedBunisess = param.id
      this.selectedBunisessName = this.businessData.find(x => x.id === param.id).name
      if (this.showType === 2) {
        // 如果是报表点击，那么需要更新报表的值
        const seriesData = this.businessData.map(x => {
          return {
            name: x.name,
            value: x.number
          }
        })
        this.loadChartsData(seriesData, param.name, param.rate)
      }

      listBusinessDetail({ keyId: this.selectedBunisess }).then(res => {
        this.showArray = res
        this.handlePageChange(1)
      })

      const searchModel = {
        keyId: this.selectedBunisess,
        stationIds: this.selectStationId
      }
      getIndustryData(searchModel).then(res => {
        this.industryData = res
      })
    },
    handlePageChange (page) {
      this.currentPageNumber = page
      const newArray = []
      for (let index = this.currentPageSize * (page - 1); index < this.currentPageSize * page; index++) {
        if (index < this.showArray.length) {
          newArray.push(this.showArray[index])
        } else {
          break
        }
      }

      this.businessDetails = newArray
    },
    initCharts (seriesData, selectedBunisessName, selectedBunisessRate) {
      if (this.dataSourcePie != null && this.dataSourcePie !== '' && this.dataSourcePie !== undefined) {
        this.dataSourcePie.dispose()
      }

      this.$nextTick(() => {
        this.dataSourcePie = echarts.init(document.getElementById('portrayAssortedChart_order'))
        this.loadChartsData(seriesData, selectedBunisessName, selectedBunisessRate)
      })
    },
    loadChartsData (seriesData, selectedBunisessName, selectedBunisessRate) {
      const option = {
        tooltip: {
          trigger: 'item'
        },
        graphic: [
          {
            type: 'text',
            style: {
              x: 140,
              y: 100,
              text: selectedBunisessName,
              textAlign: 'center',
              fill: '#dcdee2',
              font: '14px Source Han Sans CN'
            }
          },
          {
            type: 'text',
            style: {
              x: 140,
              y: 130,
              text: selectedBunisessRate + '%',
              textAlign: 'center',
              fill: '#f8f8f9',
              font: '18px Source Han Sans CN'
            }
          }
        ],
        series: [
          {
            name: '业态类型',
            type: 'pie',
            radius: ['50%', '70%'],
            center: ['50%', '50%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            data: seriesData
          }
        ]
      }

      this.dataSourcePie.setOption(option, true)
    },
    getCompareValueColor (val) {
      if (val.indexOf('高') > -1) {
        return 'text-red'
      } else if (val.indexOf('低') > -1) {
        return 'text-green'
      } else {
        return 'text-gray'
      }
    }
  },
  computed: {
    reportFontColor () {
      // 获取并设置当前页面的主题
      const theme = getStorage('oohforce-workbench-theme') || 'dark'
      return theme === 'dark' ? '#fff' : '#000'
    }
  }
}
</script>
