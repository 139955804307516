import request from '@/utils/requestV2'

// 获取某月客流录入数据
export function getmonthdata (data) {
  return request({
    url: '/ooh-dw/v1/datav/passengerflow/listFlowDetails',
    method: 'get',
    params: data
  })
}
// 预估客流量
export function getForecastFlowNumber (data) {
  return request({
    url: '/ooh-dw/v1/datav/flow/getForecastFlowNumber',
    method: 'get',
    params: data
  })
}
// 查询最近半年的数据周期
export function getLastHalfYearFlowDate (data) {
  return request({
    url: '/ooh-dw/v1/datav/flow/getLastHalfYearFlowDate',
    method: 'get',
    params: data
  })
}
// 查询最近半年的平台日客流量 按站点分组
export function countHalfYearAvgDayFlowGbStation (data) {
  return request({
    url: '/ooh-dw/v1/datav/flow/countHalfYearAvgDayFlowGbStation',
    method: 'get',
    params: data
  })
}
