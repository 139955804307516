import request from '@/utils/requestV2'
const qs = require('qs')

// 主题媒介加入排队
export function addSkuQueue (data) {
  return request({
    url: '/ooh-order/v1/orderqueue/addskuqueue',
    method: 'post',
    timeout: 200000,
    data: qs.stringify(data)
  })
}
// 查看订单的排队列表
export function getQueueList (data) {
  return request({
    url: '/ooh-order/v1/orderqueue/getqueuelist',
    method: 'post',
    timeout: 200000,
    data: qs.stringify(data)
  })
}
// 查看device的排队列表
export function getDeviceQueueList (data) {
  return request({
    url: '/ooh-order/v1/orderqueue/getdevicequeuelist',
    method: 'post',
    timeout: 200000,
    data: qs.stringify(data)
  })
}
// 设备加入排队
export function addDeviceQueue (data) {
  return request({
    url: '/ooh-order/v1/orderqueue/adddevicequeue',
    method: 'post',
    timeout: 200000,
    data: qs.stringify(data)
  })
}
// 取消某个排队
export function deleteQueue (data) {
  return request({
    url: '/ooh-order/v1/orderqueue/delete',
    method: 'post',
    timeout: 200000,
    data: qs.stringify(data)
  })
}
