import request from '@/utils/requestV2'
const qs = require('qs')

// 获取站点商家的画像-1级行业
export function countNumberGbFirst (data) {
  return request({
    url: '/ooh-dm/v1/portray/countNumberGbFirst',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取站点商家的画像-2级行业
export function countNumberGbSecond (data) {
  return request({
    url: '/ooh-dm/v1/portray/countNumberGbSecond',
    method: 'post',
    data: qs.stringify(data)
  })
}
