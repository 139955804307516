import request from '@/utils/requestV2'
// const qs = require('qs')

// 站点人口数量
export function getStationPopulation (params) {
  return request.get('/ooh-dm/v1/sun/getStationPopulation', { params })
}
// 获取站点性别画像
export function getSexLabel (params) {
  return request.get('/ooh-dm/v1/sun/getSexLabel', { params })
}
// 获取站点婚姻画像
export function getMarriageLabel (params) {
  return request.get('/ooh-dm/v1/sun/getMarriageLabel', { params })
}
// 获取站点年龄画像
export function getAgeLabel (params) {
  return request.get('/ooh-dm/v1/sun/getAgeLabel', { params })
}
// 获取站点收入画像
export function getIncomeLabel (params) {
  return request.get('/ooh-dm/v1/sun/getIncomeLabel', { params })
}
// 获取站点消费画像
export function getConsumeLabel (params) {
  return request.get('/ooh-dm/v1/sun/getConsumeLabel', { params })
}
// 获取站点教育画像
export function getEducationLabel (params) {
  return request.get('/ooh-dm/v1/sun/getEducationLabel', { params })
}
// 获取站点出行画像
export function getTravelLabel (params) {
  return request.get('/ooh-dm/v1/sun/getTravelLabel', { params })
}
// 获取站点职业画像
export function getOccupationLabel (params) {
  return request.get('/ooh-dm/v1/sun/getOccupationLabel', { params })
}
// 获取站点工作行业画像
export function getWorkLabel (params) {
  return request.get('/ooh-dm/v1/sun/getWorkLabel', { params })
}

// 获取站点周围商业数据
export function countBusinessNumber (params) {
  return request.get('/ooh-dm/v1/sun/countBusinessNumber', { params })
}
// 获取站点周围商业明细
export function listBusinessDetail (params) {
  return request.get('/ooh-dm/v1/sun/listBusinessDetail', { params })
}

// 获取数据的统计周期
export function getDataDuration () {
  return request.get('/ooh-dm/v1/sun/getDataDuration')
}
// 获取常驻人口画像标签名列表
export function getResidentLabelList () {
  return request.get('/ooh-dm/v1/sun/getResidentLabelList')
}
// 获取客流画像标签名列表
export function getTouristLabelList () {
  return request.get('/ooh-dm/v1/sun/getTouristLabelList')
}
// 获取配套设施和行业分布的标签列表
export function getIndustryLabelList (params) {
  return request.get('/ooh-dm/v1/sun/getIndustryLabelList', { params })
}
// 获取站点行业的统计数据
export function getIndustryData (params) {
  return request.get('/ooh-dm/v1/sun/getIndustryData', { params })
}

// 获取推荐的站点列表
export function getRecommendStationList (params) {
  return request({
    url: '/ooh-dm/v1/sun/getRecommendStationList',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'application/json' }
  })
}
