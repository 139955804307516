<template>
  <div class="station-new-portray">
    <Row>
      <i-col span="12">
        <span class="text-24 m-r-10">{{ stationBean.name }}</span>
        <Tooltip placement="bottom" v-if="flowDate">
          <a>数据说明<Icon type="md-help-circle" size="16" /></a>
          <div slot="content">
            <p><span class="m-r-10">数据周期</span>{{ flowDate.startDate }} 至 {{ flowDate.endDate }}</p>
            <p><span class="m-r-10">数据来源</span>地铁闸机数据</p>
          </div>
        </Tooltip>
      </i-col>
      <i-col span="12" class="text-right p-t-5">
        <Tag v-for="item in assetList" :key="item.id" :color="item.color">{{ item.alias }}</Tag>
      </i-col>
    </Row>

    <Row :gutter="8" class="m-t-5 m-b-20">
      <i-col span="8">
        <div class="station-new-portray-card">
          <p class="text-14 text-gray m-b-2">日均总客运量</p>
          <Row class="m-b-10">
            <i-col span="16" class="text-16"><b>{{formatNumber(stationFlowInTotal+stationFlowTransferTotal)}}</b></i-col>
            <i-col span="8" class="text-right text-gray" style="padding-top: 4px;">人次</i-col>
          </Row>
          <template v-if="stationFlowGb.length>1">
            <Row v-for="(item,i) in stationFlowGb" :key="'s'+i">
              <i-col span="8" class="text-gray">{{item.assetName}}</i-col>
              <i-col span="16" class="text-right">{{ formatNumber(item.flowIn+item.flowTransfer) }} <span class=" text-gray">人次</span></i-col>
            </Row>
          </template>
        </div>
      </i-col>
      <template v-if="stationFlowGb.length>1">
        <i-col span="8">
          <div class="station-new-portray-card">
            <p class="text-14 text-gray m-b-2">日均车站客运量</p>
            <Row class="m-b-10">
              <i-col span="16" class="text-16"><b>{{formatNumber(stationFlowInTotal)}}</b></i-col>
              <i-col span="8" class="text-right text-gray" style="padding-top: 4px;">人次</i-col>
            </Row>
            <Row v-for="(item,i) in stationFlowGb" :key="'s'+i">
              <i-col span="8" class="text-gray">{{item.assetName}}</i-col>
              <i-col span="16" class="text-right">{{ formatNumber(item.flowIn) }} <span class=" text-gray">人次</span></i-col>
            </Row>
          </div>
        </i-col>
        <i-col span="8">
          <div class="station-new-portray-card">
            <p class="text-14 text-gray m-b-2">日均换乘客运量</p>
            <Row class="m-b-10">
              <i-col span="16" class="text-16"><b>{{formatNumber(stationFlowTransferTotal)}}</b></i-col>
              <i-col span="8" class="text-right text-gray" style="padding-top: 4px;">人次</i-col>
            </Row>
            <Row v-for="(item,i) in stationFlowGb" :key="'s'+i">
              <i-col span="8" class="text-gray">{{item.assetName}}</i-col>
              <i-col span="16" class="text-right">{{ formatNumber(item.flowTransfer) }} <span class=" text-gray">人次</span></i-col>
            </Row>
          </div>
        </i-col>
      </template>
    </Row>

    <Tabs :value="tabType" size="small" @on-click="handleChangeTab">
      <TabPane :label="tabRebder('常驻人口')" name="name1"></TabPane>
      <TabPane :label="tabRebder('游客画像')" name="name2"></TabPane>
      <TabPane :label="tabRebder('业态分布')" name="name3"></TabPane>
      <TabPane :label="tabRebder('配套分布')" name="name4"></TabPane>
    </Tabs>

    <p v-if="dataDuration">
      <Tooltip placement="bottom-start" transfer max-width="320">
        <a>数据说明<Icon type="md-help-circle" size="16" /></a>
        <div slot="content">
          <Row>
            <i-col span="6">数据周期</i-col>
            <i-col span="18">{{dataDuration.startDate}} 至 {{dataDuration.endDate}}</i-col>
          </Row>
          <Row>
            <i-col span="6">数据来源</i-col>
            <i-col span="18">综合国家统计局、各级政府网站、多方公开数据、欧孚士数字化经营平台数据等</i-col>
          </Row>
          <Row>
            <i-col span="6">数据范围</i-col>
            <i-col span="18">地铁车站中心点半径{{dataDuration.distance}}米范围</i-col>
          </Row>
        </div>
      </Tooltip>
    </p>

    <component :is="tabComponent" ref="tabComponent"></component>
  </div>
</template>

<script>
import Assorted from './childComponents/Assorted.vue'
import Format from './childComponents/Format.vue'
import Population from './childComponents/Population.vue'
import Visitor from './childComponents/Visitor.vue'

import { toNumber } from '@/utils/wnumb_own'

import { getAssetListByStation } from '@/api/rim/asset'
import { getStationById } from '@/api/rim/station'
import { getDataDuration } from '@/api/dm/sun'
import { getLastHalfYearFlowDate, countHalfYearAvgDayFlowGbStation } from '@/api/dw/passengerflow'

export default {
  components: {
    Assorted, Format, Population, Visitor
  },
  data () {
    return {
      tabType: 'name1',
      tabComponent: 'Population',
      tabRebder: (data) => {
        return h => {
          return h('span', { class: 'text-14' }, data)
        }
      },
      svgStationId: null,
      assetList: [],
      stationBean: {},
      dataDuration: null,
      flowDate: null,
      stationFlowInTotal: 0,
      stationFlowTransferTotal: 0,
      stationFlowGb: []
    }
  },
  methods: {
    ininBaseData (param) {
      this.svgStationId = param.toString()
      const stationIds = this.svgStationId.split(',')

      // 获取站点的所属线路
      const queryModel = {
        stationIds: JSON.stringify(stationIds)
      }
      getAssetListByStation(queryModel).then(res => {
        this.assetList = res
      })
      // 获取站点的详细信息
      const queryModel2 = {
        stationId: stationIds[0]
      }
      getStationById(queryModel2).then(res => {
        this.stationBean = res
      })
      getDataDuration().then(res => {
        this.dataDuration = res
      })
      // 获取站点客流数据
      getLastHalfYearFlowDate({ stationIds: this.svgStationId }).then(res => {
        this.flowDate = res
      })
      countHalfYearAvgDayFlowGbStation({ stationIds: this.svgStationId }).then(res => {
        this.stationFlowGb = res
        let flowInTotal = 0
        let flowTransferTotal = 0
        res.forEach(element => {
          flowInTotal += element.flowIn
          flowTransferTotal += element.flowTransfer
        })

        this.stationFlowInTotal = flowInTotal
        this.stationFlowTransferTotal = flowTransferTotal
      })

      // 初始化tabs数据
      this.handleChangeTab('name1')
    },
    handleChangeTab (val) {
      this.tabType = val
      switch (val) {
        case 'name1':
          this.tabComponent = 'Population'
          break
        case 'name2':
          this.tabComponent = 'Visitor'
          break
        case 'name3':
          this.tabComponent = 'Format'
          break
        case 'name4':
          this.tabComponent = 'Assorted'
          break
        default:
          this.tabComponent = ''
          break
      }

      this.$nextTick(() => {
        this.$refs.tabComponent.ininBaseData(this.svgStationId)
      })
    },
    formatNumber (number) {
      return toNumber(number)
    }
  }
}
</script>
