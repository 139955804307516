<style>
.ivu-card-body{
  padding: 5px !important;;
}
</style>

<template>
  <div class="p-l-20 m-t-10">
    <p class="text-center text-16">站点画像（1000M以内）</p>

    <Row class="m-t-5 m-b-2 m-l-5">
      <i-col span="6">站点客流： </i-col>
      <i-col span="18">
        <p v-for="(flow,index) in stationFlows" :key="index">{{ formatNumber(flow.flowNumber) }} 人/次 {{ stationFlows.length>1? '(' + flow.assetName + ')' : '' }}</p>
      </i-col>
    </Row>

    <!-- <p class="m-t-5 m-b-2 m-l-5">
      站点客流： {{stationFlow}} 人/次
    </p> -->
    <Row>
      <i-col span="8" style="padding: 5px;cursor: pointer;" v-for="item in portrayArray" :key="item.id">
        <Card class="portray-content" :style="{'border-color': item.id===queryModel.firstId?'#2b85e4':''}">
          <div @click="loadSecondData(item.id)">
            <p>{{item.name}}</p>
            <Row>
              <i-col span="12">{{item.number}}</i-col>
              <i-col span="12">{{item.rate}}%</i-col>
            </Row>
            <Row>
              <i-col span="12">对比</i-col>
              <i-col span="12">
                <span :class="getChainRateColor(item.value)">{{item.value}}%</span>
              </i-col>
            </Row>
          </div>
        </Card>
      </i-col>
    </Row>

    <Table :columns="secondColumns" :data="secondData" stripe size="small"></Table>
    <div v-show="showArray.length>currentPageSize" class="paging_style">
        <Page size="small" :total="showArray.length" :page-size="currentPageSize" :current="currentPageNumber" @on-change="handlePageChange"></Page>
    </div>
  </div>
</template>

<script>
import { toNumber } from '@/utils/wnumb_own'
import { countNumberGbFirst, countNumberGbSecond } from '@/api/dmp/portray'
import { listLastFlowGbStation } from '@/api/dw/datav'

export default {
  data () {
    return ({
      portrayArray: [],
      queryModel: {
        firstId: null,
        stationId: null
      },

      secondColumns: [
        { title: '行业', key: 'name' },
        { title: '数量', key: 'number' },
        { title: '占比', key: 'rate' }
      ],
      secondData: [],
      showArray: [],
      currentPageSize: 8,
      currentPageNumber: 1,

      stationFlows: [] // 当前站点人流量
    })
  },
  methods: {
    initPageData (stationId) {
      const queryStationId = stationId.toString().indexOf(',') > 0 ? stationId.split(',')[0] : stationId
      this.queryModel.stationId = queryStationId

      countNumberGbFirst({ stationId: queryStationId }).then(res => {
        this.portrayArray = res
        if (res.length > 0) {
          this.loadSecondData(res[0].id)
        }
      })

      listLastFlowGbStation({ stationIds: stationId }).then(res => {
        this.stationFlows = res
      })
    },
    loadSecondData (selectId) {
      this.queryModel.firstId = selectId

      countNumberGbSecond(this.queryModel).then(res => {
        this.showArray = res
        this.handlePageChange(1)
      })
    },
    handlePageChange (page) {
      this.currentPageNumber = page
      const newArray = []
      for (let index = this.currentPageSize * (page - 1); index < this.currentPageSize * page; index++) {
        if (index < this.showArray.length) {
          newArray.push(this.showArray[index])
        } else {
          break
        }
      }

      this.secondData = newArray
    },
    getChainRateColor (number) {
      let color = 'text-red'
      if (number < 0) {
        color = 'text-green'
      } else if (number === 0) {
        color = 'text-yellow'
      }
      return color
    },
    formatNumber (number) {
      return toNumber(number)
    }
  }
}
</script>
