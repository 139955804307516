<template>
  <div class="station-new-tab">
    <Row :gutter="16" class="m-t-5">
      <i-col span="12" class="m-b-10" v-for="(item,index) in stationPopulation" :key="index">
        <div class="station-new-portray-card" style="padding: 10px;">
          <p class="text-14 text-darkgray m-b-2">
            {{item.name}}
            <Tooltip v-if="item.title" placement="bottom-start" transfer max-width="300">
              <a><Icon type="md-help-circle" size="16" /></a>
              <div slot="content">
                <p><span class="m-r-10">{{item.title}}</span>{{item.remark}}</p>
              </div>
            </Tooltip>
          </p>
          <Row>
            <i-col span="16" class="text-16"><b>{{item.value}}</b></i-col>
            <i-col span="8" class="text-right text-gray" style="padding-top: 4px;">{{item.unit}}</i-col>
          </Row>

          <p v-if="item.title" class="text-right">
            <span class="text-gray">-</span>
          </p>
          <p v-else class="text-right">
            <span :class="getCompareValueColor(item.compareValue)">{{ item.compareValue }}</span>
            <span class="text-gray"> 与本市均值</span>
          </p>

          <!-- <p class="text-right" :class="getCompareValueColor(item.compareValue)">{{ item.compareValue }}</p> -->
        </div>
      </i-col>
    </Row>

    <p class="m-b-10">
      <span class="text-16">标签</span><span class="text-darkgray"> （单位:%）</span>
    </p>

    <!-- 标签一 -->
    <div class="m-b-20">
      <div class="station-new-tag-title">性别</div>
      <div class="station-new-tag-process">
        <div v-for="(content,j) in tabArray_sex" :key="j" class="station-new-tag-content" :style="{'width': content.value + '%', 'background-color': tabColor1[j]}">
          {{ content.name }} {{ content.value }}
        </div>
      </div>
    </div>
    <div class="m-b-20">
      <div class="station-new-tag-title">婚姻</div>
      <div class="station-new-tag-process">
        <div v-for="(content,j) in tabArray_marriage" :key="j" class="station-new-tag-content" :style="{'width': content.value + '%', 'background-color': tabColor1[j]}">
          {{ content.name }} {{ content.value }}
        </div>
      </div>
    </div>

    <!-- 标签二 -->
    <div class="m-b-20">
      <div class="station-new-tag-title">年龄</div>
      <div class="station-new-tag-process">
        <div v-for="(content,j) in tabArray_age" :key="j" class="inline" :style="{'width': content.value + '%'}">
          <p>
            <Tooltip v-if="formatTagName(content.name, content.value).indexOf('.')>0" :content="content.name" placement="top-end">
              <span class="text-darkgray">{{ formatTagName(content.name, content.value) }}</span>
            </Tooltip>
            <span v-else class="text-darkgray">{{ content.name }}</span>
          </p>
          <div class="station-new-tag-content" :style="{'width': '100%', 'background-color': tabColor2[6-tabArray_age.length +j]}">
            {{ content.value }}
          </div>
        </div>
      </div>
    </div>
    <div class="m-b-20">
      <div class="station-new-tag-title">教育</div>
      <div class="station-new-tag-process">
        <div v-for="(content,j) in tabArray_education" :key="j" class="inline" :style="{'width': content.value + '%'}">
          <p>
            <Tooltip v-if="formatTagName(content.name, content.value).indexOf('.')>0" :content="content.name" placement="top-end">
              <span class="text-darkgray">{{ formatTagName(content.name, content.value) }}</span>
            </Tooltip>
            <span v-else class="text-darkgray">{{ content.name }}</span>
          </p>
          <div class="station-new-tag-content" :style="{'width': '100%', 'background-color': tabColor2[6-tabArray_education.length +j]}">
            {{ content.value }}
          </div>
        </div>
      </div>
    </div>

    <!-- 标签三 -->
    <!-- <p class="text-14 text-darkgray" style="padding-left: 12px;margin-bottom: 10px;">行业</p> -->
    <p class="m-b-10"><span class="text-16">行业</span></p>
    <div v-for="(content,j) in tabArray_work" :key="j" style="padding-left: 12px;" class="m-b-10">
      <div class="inline text-darkgray" style="width: 90px;">{{ content.name }}</div>
      <div class="station-new-tag-content-single" :style="{'width': content.value + '%'}"></div>
      <div class="inline m-l-5">{{ content.value }}%</div>
    </div>

  </div>
</template>

<script>
import {
  getStationPopulation, getSexLabel, getMarriageLabel,
  getAgeLabel, getEducationLabel, getWorkLabel
} from '@/api/dm/sun'

export default {
  data () {
    return {
      tabColor1: ['#e84393', '#0984e3'],
      tabColor2: ['#122635', '#113857', '#0e4977', '#0d5790', '#0b6bb5', '#0984e3'],

      stationPopulation: [],
      tabArray_sex: [], // 性别
      tabArray_marriage: [], // 婚姻
      tabArray_age: [], // 年龄
      tabArray_education: [], // 教育
      tabArray_work: [] // 工作行业
    }
  },
  methods: {
    ininBaseData (svgStationId) {
      const queryModel = {
        personType: 2, // 人口类型(1.常驻;2:客流;)
        stationIds: svgStationId
      }

      // 获取站点的人口数据
      getStationPopulation(queryModel).then(res => {
        if (res.length > 0) {
          this.stationPopulation = [
            {
              name: '月均游客',
              title: '月均游客',
              remark: '基于手机定位和信令数据，计算进入该区域并停留一定时长（提出过境人群，停留时长根据统计范围大小有区别）得到的单月实际到访人次。',
              value: (res[0].number / 10000).toFixed(2),
              unit: '万人',
              compareValue: res[0].compareValue,
              rate: res[0].rate
            },
            {
              name: '月均游客密度',
              remark: '',
              value: res[0].value,
              unit: '万人/K㎡',
              isUp: res[0].compareValue.indexOf('高') > -1,
              compareValue: res[0].compareValue
            }
          ]
        } else {
          this.stationPopulation = []
        }
      })
      // 获取性别标签
      getSexLabel(queryModel).then(res => {
        this.tabArray_sex = res
      })
      // 获取婚姻标签
      getMarriageLabel(queryModel).then(res => {
        this.tabArray_marriage = res
      })
      // 获取年龄标签
      getAgeLabel(queryModel).then(res => {
        this.tabArray_age = res
      })
      // 获取教育标签
      getEducationLabel(queryModel).then(res => {
        this.tabArray_education = res
      })
      // 获取工作行业标签
      getWorkLabel(queryModel).then(res => {
        this.tabArray_work = res
      })
    },
    formatTagName (name, value) {
      if (value < 10) {
        return name.substr(0, 2) + '.'
      } else if (value < 13) {
        return name.substr(0, 3) + '.'
      } else if (value < 20) {
        return name.substr(0, 4) + '.'
      } else {
        return name
      }
    },
    getCompareValueColor (val) {
      if (val.indexOf('高') > -1) {
        return 'text-red'
      } else if (val.indexOf('低') > -1) {
        return 'text-green'
      } else {
        return 'text-gray'
      }
    }
  }
}
</script>
