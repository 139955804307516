<template>
  <Modal v-model="modelRecord" width="1000" :footer-hide="true">
    <Card :bordered="false" dis-hover>
      <p slot="title">媒体属性</p>
      <Row class="m-b-5">
        <i-col :xs="12" :sm="12" :md="8" :lg="8">
          <Label class="title m-r-5">资源编号</Label>
          <span>{{resourceModel.code}}</span>
        </i-col>
        <i-col :xs="12" :sm="12" :md="8" :lg="8">
          <Label class="title m-r-5">所属资产</Label>
          <span>{{resourceModel.assetName}}</span>
        </i-col>
        <i-col :xs="12" :sm="12" :md="8" :lg="8">
          <Label class="title m-r-5">站点名称</Label>
          <span>{{resourceModel.stationName }}</span>
        </i-col>
      </Row>
      <Row class="m-b-5">
        <i-col :xs="12" :sm="12" :md="8" :lg="8">
          <Label class="title m-r-5">媒体类型</Label>
          <span>{{resourceModel.devicemodelName }}</span>
        </i-col>
      </Row>
    </Card>
    <Card :bordered="false" dis-hover>
      <p slot="title">资源锁位记录</p>

      <Table :columns="columns" :data="recordBeanList" stripe></Table>
    </Card>

    <Card v-if="commonQueue && isInOrder" :bordered="false" dis-hover>
      <p slot="title">排队情况</p>

      <p v-if="showTips" class="text-orange m-t-5">
        提示：您选择的发布档期为多个，因释放和占用时长不一致故无法排队，请选择单个档期进行操作（请在左侧“媒介采购”中选中单个档期）！
      </p>
      <Table v-else :columns="queueColumns" :data="queueList" stripe></Table>

      <p class="text-right p-t-10">
        <Button type="success" :disabled="showTips" size="small" @click="handleAddQueue">一键加入排队</Button>
      </p>
    </Card>
  </Modal>
</template>

<script>
import { formatOrderStatus } from '@/utils/tagStatus'
import { GetCurrentSchedule } from '@/utils/dateFormat'

import { getDevice } from '@/api/rim/device'
import { getResourceExpiretime } from '@/api/order/productdetail'
import { getDeviceQueueList, addDeviceQueue } from '@/api/order/orderqueue'

export default {
  data () {
    return {
      // 是否显示售出品牌情况按钮
      resourceShowBrand: this.$store.getters.token.publisherSetting.resourceShowBrand,
      // 常规资源是否可以排队
      commonQueue: this.$store.getters.token.publisherSetting.commonQueue,
      isInOrder: false,
      // 显示多档期提示
      showTips: false,

      modelRecord: false,
      resourceModel: {
        code: '',
        assetName: '',
        stationName: ''
      },
      resourceRecordQuery: {
        deviceId: 0,
        startDate: '',
        endDate: ''
      },
      recordBeanList: [],
      columns: [
        {
          title: '售卖档期',
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.startDate + ' 至 ' + params.row.endDate)
          }
        },
        {
          title: '状态',
          align: 'center',
          render: (h, params) => {
            return formatOrderStatus(h, params.row.orderStatus, params.row.orderStatusName)
          }
        },
        { title: '发布类型', align: 'center', key: 'orderTypeName' },
        { title: '释放时间', align: 'center', key: 'expireTime' },
        { title: '所属客户', align: 'center', key: 'advertiserName' },
        { title: '投放品牌', align: 'center', key: 'brandName' },
        { title: '用户', align: 'center', key: 'userName' }
      ],

      queueList: [],
      queueColumns: [
        {
          title: '顺位',
          align: 'center',
          render: (h, data) => {
            return h('span', data.index + 1)
          }
        },
        {
          title: '排队档期',
          render: (h, data) => {
            return h('span', GetCurrentSchedule(data.row.startDate, data.row.endDate))
          }
        },
        { title: '排队用户', key: 'userName' },
        { title: '创建时间', key: 'createTime' },
        { title: '预计释放时间', key: 'expireTime' }
      ],
      searchQueueModel: null
    }
  },
  methods: {
    initResourceRecordData (deviceId, searchSchedules, orderId) {
      this.modelRecord = true
      this.showTips = searchSchedules.length > 1
      this.isInOrder = (orderId && orderId !== null)
      this.loadQueueInfo(deviceId, searchSchedules, orderId)

      getDevice({ deviceId: deviceId }).then(res => {
        this.resourceModel = res
      })

      // 获取售卖记录
      this.resourceRecordQuery.deviceId = deviceId
      this.resourceRecordQuery.startDate = searchSchedules[0].startDate
      this.resourceRecordQuery.endDate = searchSchedules[searchSchedules.length - 1].endDate

      getResourceExpiretime(this.resourceRecordQuery).then((res) => {
        if (res && !res.errcode) {
          this.recordBeanList = res
          // 如果仅显示品牌，那么移除最后3条数据
          if (this.resourceShowBrand !== 2 && this.columns.length === 7) {
            this.columns.splice(4, 3)
          }
        } else {
          this.$Notice.error({ title: '获取资源锁位记录失败', desc: res.errmsg })
        }
      }).catch((err) => {
        this.$Notice.error({ title: '获取资源锁位记录出错', desc: err })
      })
    },
    loadQueueInfo (deviceId, searchSchedules, orderId) {
      // 如果是多档期，那么不执行查询操作
      if (this.showTips || !orderId) { return }

      this.searchQueueModel = {
        deviceId: deviceId,
        endDate: searchSchedules[0].endDate,
        orderId: orderId,
        startDate: searchSchedules[0].startDate
      }

      getDeviceQueueList(this.searchQueueModel).then(res => {
        this.queueList = res
      })
    },
    handleAddQueue () {
      addDeviceQueue(this.searchQueueModel).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({
            title: '资源排队成功!',
            desc: '请在页面右侧【查看排队清单】中查看详情！'
          })
          // 更新排队清单列表
          getDeviceQueueList(this.searchQueueModel).then(res2 => {
            this.queueList = res2
          })
        }
      })
    }
  }
}
</script>
