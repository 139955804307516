<style scoped>
.svg-station-content{
  position: relative;
}
.download-svg-css{
  width: 1280px;
  height: 768px;
  z-index: -1;
  position: absolute;
  top: 50px;
}
</style>
<style lang="less">
.vertical-center-modal{
    display: flex;
    align-items: center;
    justify-content: center;
    .ivu-modal{
        top: 0;
    }
}
</style>
<style>
.ivu-modal-close {
  top: 2px;
}
</style>

<template>
  <div>
    <Modal v-model="isShow" :width="showStationPortray?1600:1200" footer-hide :fullscreen="isFullscreen" :styles="{top: '5px'}" :class-name="'vertical-center-modal'">
      <div class="fullscreen-content" @click="isFullscreen = !isFullscreen">
        <template v-if="isFullscreen">
          <Icon type="ios-contract" size="16" /> 关闭全屏
        </template>
        <template v-else>
          <Icon type="ios-expand" size="16" /> 全屏显示
        </template>
      </div>

      <Row class="m-t-10">
        <i-col :span="showStationPortray?17:24">
          <Row class="text-center" style="min-height:750px;" :gutter="8">
            <i-col v-if="svgShowType===2" span="24">
              PS: 您正在编辑订单点位，默认档期为：
              <span class="text-orange">{{purchaseSchedules}}</span>
              <span v-if="duration>0">，采购时长：<span class="text-orange">{{duration}} 秒</span></span>
            </i-col>
            <i-col  v-else-if="svgShowType===3" span="24">
              <span class="text-orange">您正在查看订单采购详情，无法编辑点位，请跳转至订单编辑窗口！</span>
            </i-col>

            <i-col span="24">
                <Tabs v-if="svgList.length>0" v-model="defaultTab" :animated="false" @on-click="changeTabs">
                    <div v-if="svgShowType===4 && showAllDemandButton" slot="extra" class="p-t-5 m-r-10">
                      <Button size="small" type="info" ghost @click="handleShowAllDemand">列表查看全部上刊</Button>
                    </div>
                    <div v-else-if="svgShowType===6" slot="extra" class="p-t-5 m-r-10">
                      <Button size="small" type="info" ghost @click="handleShowOtherWorkItem">显示指定资源设备的任务</Button>
                    </div>

                    <TabPane v-for="(item, index) in svgList" :key="index" :label="stationLabel(index)" :name="'tab'+index">
                      <ul style="list-style-type:none;">
                        <template v-if="svgShowType===4" >
                          <!-- <li class="tipBox"><a href="javascrip:void(0);" style="background-color:#B6B6B7;border: 1px solid #F54236;"></a>休耕</li> -->
                          <li class="tipBox"><a href="javascrip:void(0);" style="background-color:#B6B6B7"></a>不可用</li>
                          <li class="tipBox"><a href="javascrip:void(0);" style="background-color:#008100"></a>空刊点位</li>
                          <li class="tipBox"><a href="javascrip:void(0);" style="background-color:#F54236"></a>当前在刊 / 作业点位</li>
                        </template>

                        <template v-else-if="svgShowType===5" >
                          <!-- <li class="tipBox"><a href="javascrip:void(0);" style="background-color:#B6B6B7;border: 1px solid #F54236;"></a>休耕</li> -->
                          <li class="tipBox"><a href="javascrip:void(0);" style="background-color:#B6B6B7"></a>不可用</li>
                          <li class="tipBox"><a href="javascrip:void(0);" style="background-color:#F54236"></a>待处理</li>
                        </template>

                        <template v-else-if="svgShowType===6">
                          <!-- <li class="tipBox"><a href="javascrip:void(0);" style="background-color:#B6B6B7;border: 1px solid #F54236;"></a>休耕</li> -->
                          <li class="tipBox"><a href="javascrip:void(0);" style="background-color:#B6B6B7"></a>不可用</li>
                          <li class="tipBox"><a href="javascrip:void(0);" style="background-color:#008100"></a>无任务媒体</li>
                          <li class="tipBox"><a href="javascrip:void(0);" style="background-color:#F54236"></a>作业媒体</li>
                        </template>

                        <template v-else>
                          <!-- <li class="tipBox"><a href="javascrip:void(0);" style="background-color:#B6B6B7;border: 1px solid #F54236;"></a>休耕</li> -->
                          <li class="tipBox"><a href="javascrip:void(0);" style="background-color:#B6B6B7"></a>不可用</li>
                          <li class="tipBox"><a href="javascrip:void(0);" style="background-color:#FFEB3A"></a>已售出</li>
                          <li class="tipBox"><a href="javascrip:void(0);" style="background-color:#008100"></a>可售卖</li>
                          <li v-if="svgShowType!==1" class="tipBox"><a href="javascrip:void(0);" style="background-color:#F54236"></a>当前已选中</li>
                        </template>

                        <li class="tipBox2"><a @click="handleDownloadMap(index)"><Icon size="18" type="md-cloud-download" class="m-r-5"/>下载当前站内地图</a></li>
                        <li class="tipBox2" v-if="isShowChange3D"><a @click="handleChangeMode"><Icon size="18" type="md-planet" class="m-r-5"/>切换3D模式</a></li>
                      </ul>

                      <div style="clear:both;"></div>
                      <Row>
                        <i-col v-if="showButtonIcons.length>0" span="2" class="icon-legend p-t-10">
                          显示媒体编号
                          <i-switch  v-model="switchShowType" @on-change="changeShowType" size="large">
                            <span slot="open"> 开启 </span>
                            <span slot="close"> 关闭 </span>
                          </i-switch>
                          <Divider  style="margin: 15px 0;"/>
                          <h3 class="p-b-5">站点图例</h3>
                          <div v-for="(icon,index) in showButtonIcons" :key="index" :class="chooseIcon===icon.deviceModels? 'icon-content-active':'icon-content'" @click="handleCheckIcon(icon.deviceModels)">
                            <p class="m-b-5">{{icon.title.substr(0,7)}}</p>
                            <Row v-if="!!icon.mergeHtml" >
                              <i-col span="12"><svg v-html="icon.svg" style="height:24px"></svg></i-col>
                              <i-col span="12"><svg v-html="icon.mergeHtml" style="height:24px"></svg></i-col>
                            </Row>
                            <svg v-else v-html="icon.svg" style="height:24px"></svg>
                          </div>
                        </i-col>
                        <i-col :span="(showButtonIcons.length>0)?22:24">
                          <div v-html="item.svg" name="svgStationHtml" :style="{ height: isFullscreen? '840px':'680px'  }" class="svg-station-content" :id="svgId + '_' + index"></div>
                        </i-col>
                      </Row>
                    </TabPane>
                </Tabs>

                <div v-else class="default-content">
                  <p style="font-size: xx-large;">抱歉，暂无SVG站点地图</p>
                </div>
            </i-col>
          </Row>
        </i-col>
        <i-col span="7" v-if="showStationPortray">
          <stationProtray2 v-if="showNewPortray" ref="StationProtray2"/>
          <stationProtray v-else ref="StationProtray"/>
        </i-col>
      </Row>

      <!-- 隐藏图层，用于下载站点地图时，克隆SVG代码 -->
      <div :id="'download-svg-' + svgId" class="download-svg-css"></div>
      <Spin size="large" fix v-if="spinShow">
          <Icon type="ios-loading" size=18 class="-spin-icon-load"></Icon>
          <div>地图加载中</div>
      </Spin>
    </Modal>

    <resourceRecord ref="resourceRecord"/>
  </div>
</template>

<script>
import svgPanZoom from 'svg-pan-zoom'
import resourceRecord from './ResourceRecord'
import stationProtray from './StationPortray'
import stationProtray2 from './StationPortray2'

import { HTMLEncode } from '@/utils'
import { newGuid } from '@/utils/mapCommon'
import { GetCurrentSchedule } from '@/utils/dateFormat'
import { downloadFilePostRequest } from '@/utils/download'

import { check3d, getRimStationSvgByStationId } from '@/api/rim/stationgraph'
import { getStationSvgByStationId } from '@/api/product/stationgraph'
import { getSoldResourceInfo } from '@/api/order/productdetail'
import { getIcon } from '@/api/draw/svg'

export default {
  props: {
    stationSvgModal: { type: Boolean, required: true },
    stationId: { type: [String, Number], required: true },
    searchSchedules: { type: Array, required: true },
    svgShowType: { type: Number, required: true }, // 1资源库存 // 2订单购买地图展示，3订单详情资源展示 4显示上刊数据，5设备管理

    assetIds: { type: String, default: '' },
    orderId: { type: [Number, String], default: null },
    duration: { type: Number, default: 0 },
    showAllDemandButton: { type: Boolean, default: true },
    showStationPortray: { type: Boolean, default: false }
  },
  components: {
    resourceRecord, stationProtray, stationProtray2
  },
  data () {
    return ({
      isFullscreen: false,
      isSvgLoadingFisished: false,
      isShow: false,
      spinShow: false,
      isShowChange3D: false,

      svgList: [],
      defaultTab: 'tab0',
      svgId: '',
      stationTabLables: [], // 用于标注点位数量

      checkSchedules: [], // checkbox选中的多项档期

      // 是否显示售出品牌情况按钮
      resourceShowBrand: this.$store.getters.token.publisherSetting.resourceShowBrand,
      showNewPortray: this.$store.getters.token.publisherSetting.stationPortray === 2, // 是否显示新版站点画像

      buttonIcons: [],
      showButtonIcons: [], // 需要显示的ICON清单
      chooseIcon: null,
      existSvgZoom: [],
      buttonIconsSizeLarge: [1, 2, 4, 5, 6, 68, 72, 77, 79, 80, 81, 87, 88],
      buttonIconsSizeSmall: [78],

      switchShowType: true, // 切换显示媒体编号

      tagDeviceIds: []
    })
  },
  methods: {
    initPageData () {
      this.spinShow = true
      this.chooseIcon = null
      this.existSvgZoom = []
      this.tagDeviceIds = []
      this.loadIcons()
      this.check3D()
      // 显示站点画像数据
      if (this.showStationPortray) {
        if (this.showNewPortray) {
          this.$refs.StationProtray2.ininBaseData(this.stationId)
        } else {
          this.$refs.StationProtray.initPageData(this.stationId)
        }
      }

      // 仅需要请求获取原始的SVG清单，主要用于设备运维
      if (this.searchSchedules === null || this.searchSchedules.length === 0) {
        getRimStationSvgByStationId({ stationIds: this.stationId }).then(res => {
          this.beginLoadSvgMap(res)
        }).catch(() => {
          this.$nextTick(() => { this.$emit('svg-finished') })
        })
      } else {
        const postData = {
          stationIds: this.stationId,
          assetIds: (this.assetIds === '' || this.assetIds === null) ? null : this.assetIds,
          orderId: this.orderId === 0 ? undefined : this.orderId,
          schedules: (this.searchSchedules === null || this.searchSchedules.length === 0 || this.svgShowType > 3) ? [] : JSON.stringify(this.searchSchedules)
        }

        getStationSvgByStationId(postData).then(res => {
          this.beginLoadSvgMap(res)
        }).catch(() => {
          this.$nextTick(() => { this.$emit('svg-finished') })
        })
      }
    },
    beginLoadSvgMap (svgMap) {
      this.isSvgLoadingFisished = false
      this.svgList = svgMap
      this.stationTabLables = []
      let label

      // 判断是否为换乘站
      const resultarr = [...new Set(this.svgList.map(x => x.stationId))]

      for (let index = 0; index < this.svgList.length; index++) {
        label = (this.svgList[index].floor > 0) ? '地上' : '地下'
        label += this.svgList[index].floor + '层' + this.svgList[index].positionName

        if (resultarr.length > 1) {
          label = this.svgList[index].assetName.substr(this.svgList[index].assetName.indexOf('铁') + 1) + ' ' + label
        }

        this.stationTabLables.push({
          stationName: this.svgList[index].stationName,
          label: label,
          quantity: 0
        })
      }

      if (this.svgList.length > 0) {
        this.changeTabs('tab0')
      } else {
        this.isSvgLoadingFisished = true
      }
      this.svgLoadingFisished()
    },
    beginSvgPanZoom (svgId) {
      if (this.existSvgZoom.indexOf(svgId) > -1) {
        return
      } else {
        this.existSvgZoom.push(svgId)
      }

      this.$nextTick(() => {
        const currentSvgElement = document.getElementById(svgId).firstElementChild
        // 设置svg呈现在画板上的尺寸
        currentSvgElement.setAttribute('width', '100%')
        currentSvgElement.setAttribute('height', '100%')

        var curMap = svgPanZoom(currentSvgElement, {
          panEnabled: true,
          controlIconsEnabled: false,
          zoomEnabled: true,
          dblClickZoomEnabled: true,
          center: true
        }).zoom(1.3)

        // 仅限新版SVG，需要添加鼠标移入移除事件
        if (currentSvgElement.id === '') {
          curMap.zoom(1.5)
          this.addMapMouseEvent(currentSvgElement)
        }
      })
    },
    changeTabs (name) {
      this.defaultTab = name
      const currentSvgId = this.svgId + '_' + name.substr(3)
      this.beginSvgPanZoom(currentSvgId)

      // 确认需要显示的ICON
      this.showButtonIcons = []
      this.chooseIcon = null
      let newEl = null

      this.$nextTick(() => {
        const gNodeList = document.getElementById(currentSvgId).getElementsByTagName('g')
        if (gNodeList.length > 0) {
          for (let j = 0; j < gNodeList.length; j++) {
            newEl = gNodeList[j]

            if (newEl.hasAttribute('data-devicemodelid')) {
              // 双面电子屏, 同时不包含301的电子屏
              if (newEl.getAttribute('data-devicemodelid') === '306') {
                if (!this.showButtonIcons.find(x => x.deviceModels === '301')) {
                  this.showButtonIcons.push(
                    this.buttonIcons.find(x => x.deviceModels === '301')
                  )
                }
              } else if (!this.showButtonIcons.find(x => x.deviceModels === newEl.getAttribute('data-devicemodelid')) &&
                this.buttonIcons.find(x => x.deviceModels === newEl.getAttribute('data-devicemodelid'))) {
                this.showButtonIcons.push(
                  this.buttonIcons.find(x => x.deviceModels === newEl.getAttribute('data-devicemodelid'))
                )
              }
            }
          }
        }

        // 切换显示出媒体编号
        this.changeShowType()
      })
    },
    loadIcons () {
      getIcon().then(res => {
        this.buttonIcons = res.map((item) => {
          let svgHtml = item.svg
          let svgTitle = item.title
          let mergeHtml = ''
          // 设置部分图标放大比例显示
          if (this.buttonIconsSizeLarge.includes(item.id)) {
            svgHtml = svgHtml.replace('<g>', '<g transform="scale(1.5)">')
          } else if (this.buttonIconsSizeSmall.includes(item.id)) {
            svgHtml = svgHtml.replace('<g>', '<g transform="scale(0.7)">')
          }

          if (item.deviceModels === '114') {
            svgTitle = '柱贴'
            mergeHtml = res.filter(x => x.deviceModels === '114')[1].svg
          } else if (item.deviceModels === '124') {
            svgTitle = '包柱灯箱'
            mergeHtml = res.filter(x => x.deviceModels === '124')[1].svg
          }

          return {
            title: svgTitle,
            svg: svgHtml,
            mergeHtml: mergeHtml,
            deviceModels: item.deviceModels // ICON对应的媒体类型ID
          }
        })
      })
    },
    tagResource (resourceBeanList) {
      if (resourceBeanList.length === 0) { return }

      const _self = this
      const pathNodeList = document.getElementsByTagName('path')
      const gNodeList = document.getElementsByTagName('g')
      const deviceIds = resourceBeanList.map(x => x.deviceId)
      this.tagDeviceIds = resourceBeanList.map(x => x.deviceId.toString())
      this.formatSvgTaglable(resourceBeanList)

      if (pathNodeList.length > 0) {
        for (let i = 0; i < pathNodeList.length; i++) {
          const el = pathNodeList[i]

          if (el.hasAttribute('data-id')) {
            // 判断灯箱点位是否包含在清单中
            const deviceId = parseInt(el.getAttribute('data-id'))
            if (deviceIds.indexOf(deviceId) > -1) {
              el.setAttribute('fill', '#F54236') // 红色

              if (this.svgShowType !== 3) { // 订单详情，不再支持点击操作
                el.setAttribute('cursor', 'pointer')
                // 注册点击事件，并调用父组件方法
                el.onclick = () => {
                  _self.$emit('on-select', deviceId)
                }
              }
            }
          }
        }
      }

      // 新版地图使用g
      if (gNodeList.length > 0) {
        for (let j = 0; j < gNodeList.length; j++) {
          const newEl = gNodeList[j]

          if (newEl.hasAttribute('data-id')) {
            // 判断灯箱点位是否包含在清单中
            const deviceId = parseInt(newEl.getAttribute('data-id'))
            if (deviceIds.indexOf(deviceId) > -1) {
              // 此处要对异性墙贴使用特殊方式处理
              if (newEl.getAttribute('data-devicemodelid') === '110') {
                for (let h = 0; h < newEl.children.length; h++) {
                  if (newEl.children[h].nodeName === 'g') { // 多个元素组，合并组合而成的墙贴
                    for (let gIndex = 0; gIndex < newEl.children[h].children.length; gIndex++) {
                      newEl.children[h].children[gIndex].setAttribute('stroke-width', '1')
                      newEl.children[h].children[gIndex].setAttribute('stroke', '#F54236')
                    }
                  } else if (newEl.children[h].nodeName === 'text') {
                    newEl.children[h].setAttribute('fill', '#F54236') // 设置文字编号
                  } else {
                    newEl.children[h].setAttribute('stroke', '#F54236')
                    newEl.children[h].setAttribute('stroke-width', '1')
                  }
                }
              } else {
                newEl.setAttribute('fill', '#F54236') // 红色
                const gChild = newEl.children
                for (let k = 0; k < gChild.length; k++) {
                  gChild[k].setAttribute('fill', '#F54236') // 红色
                }
              }

              if (this.svgShowType !== 3) { // 订单详情，不再支持点击操作
                newEl.setAttribute('cursor', 'pointer')
                // 注册点击事件，并调用父组件方法
                newEl.onclick = () => {
                  _self.$emit('on-select', deviceId)
                }
              }
            }
          }
        }
      }
    },
    tagResourceAndName (resourceBeanList) {
      const pathNodeList = document.getElementsByTagName('path')
      const gNodeList = document.getElementsByTagName('g')
      const deviceIds = resourceBeanList.map(x => x.deviceId)
      this.tagDeviceIds = resourceBeanList.map(x => x.deviceId.toString())
      const _self = this
      this.formatSvgTaglable(resourceBeanList)

      if (pathNodeList.length > 0 && resourceBeanList.length > 0) {
        for (let i = 0; i < pathNodeList.length; i++) {
          const el = pathNodeList[i]

          if (el.hasAttribute('data-id')) {
            // 判断灯箱点位是否包含在清单中
            const deviceId = parseInt(el.getAttribute('data-id'))
            if (deviceIds.indexOf(deviceId) > -1) {
              el.setAttribute('fill', '#F54236') // 红色

              el.setAttribute('cursor', 'pointer')
              // 注册点击事件，并调用父组件方法
              el.onclick = () => {
                _self.$emit('on-select', deviceId)
              }

              // 在路劲内部添加上刊品牌
              const demandEntity = resourceBeanList.find(x => x.deviceId === deviceId)
              const textElement = this.fillPathText(demandEntity.brandName, deviceId, false)
              textElement.onclick = () => {
                _self.$emit('on-select', deviceId)
              }
              el.after(textElement)
            }
          }
        }
      }

      // 新版地图使用g
      if (gNodeList.length > 0) {
        for (let j = 0; j < gNodeList.length; j++) {
          const newEl = gNodeList[j]

          if (newEl.hasAttribute('data-id')) {
            // 判断灯箱点位是否包含在清单中
            const deviceId = parseInt(newEl.getAttribute('data-id'))
            if (deviceIds.indexOf(deviceId) > -1) {
              newEl.setAttribute('checked', 'true')
              newEl.setAttribute('cursor', 'pointer')
              let elBBox = { x: 0, y: 0 }

              // 此处要对异性墙贴使用特殊方式处理
              if (newEl.getAttribute('data-devicemodelid') === '110') {
                for (let h = 0; h < newEl.children.length; h++) {
                  if (newEl.children[h].nodeName === 'g') { // 多个元素组，合并组合而成的墙贴
                    for (let gIndex = 0; gIndex < newEl.children[h].children.length; gIndex++) {
                      newEl.children[h].children[gIndex].setAttribute('stroke-width', '1')
                      newEl.children[h].children[gIndex].setAttribute('stroke', '#F54236')

                      if (elBBox.x === 0) {
                        if (newEl.children[h].children[gIndex].hasAttribute('x1')) {
                          elBBox = {
                            x: parseFloat(newEl.children[h].children[gIndex].getAttribute('x1')),
                            y: parseFloat(newEl.children[h].children[gIndex].getAttribute('y1'))
                          }
                        }
                      }
                    }
                  } else if (newEl.children[h].nodeName === 'text') {
                    newEl.children[h].setAttribute('fill', '#F54236') // 设置文字编号
                  } else {
                    newEl.children[h].setAttribute('stroke', '#F54236')
                    newEl.children[h].setAttribute('stroke-width', '1')

                    if (elBBox.x === 0) {
                      if (newEl.children[h].hasAttribute('x1')) {
                        elBBox = {
                          x: parseFloat(newEl.children[h].getAttribute('x1')),
                          y: parseFloat(newEl.children[h].getAttribute('y1'))
                        }
                      }
                    }
                  }
                }
              } else {
                newEl.setAttribute('fill', '#F54236') // 红色
                const gChild = newEl.children
                for (let k = 0; k < gChild.length; k++) {
                  // 文字不用呗设置为空色
                  if (gChild[k].nodeName !== 'text') {
                    gChild[k].setAttribute('fill', '#F54236') // 红色
                  }

                  // 找到第一个元素位置信息， 用于定位文字信息
                  if (elBBox.x === 0) {
                    if (gChild[k].hasAttribute('x1')) {
                      elBBox = {
                        x: parseFloat(gChild[k].getAttribute('x1')),
                        y: parseFloat(gChild[k].getAttribute('y1'))
                      }
                    } else if (gChild[k].hasAttribute('x')) {
                      elBBox = {
                        x: parseFloat(gChild[k].getAttribute('x')),
                        y: parseFloat(gChild[k].getAttribute('y'))
                      }
                    } else if (gChild[k].nodeName === 'path') {
                      const pathBox = gChild[k].getAttribute('d').split('m')[1].split('c')[0].split(',')
                      elBBox = {
                        x: parseFloat(pathBox[0]),
                        y: parseFloat(pathBox[1])
                      }
                    }
                  }
                }
              }

              // 注册点击事件，并调用父组件方法
              newEl.onclick = () => {
                _self.$emit('on-select', deviceId)
              }

              // 在路劲内部添加上刊品牌
              const demandEntity = resourceBeanList.find(x => x.deviceId === deviceId)
              newEl.appendChild(this.fillPathText(demandEntity.brandName, deviceId, false, true, elBBox))
              if (demandEntity.brandName) { // 电子屏的上刊品牌为数组
                newEl.setAttribute('brandFullName', demandEntity.brandName)
              }
            }
          }
        }
      }
    },
    tagPurchase (resourceBeanList) {
      // 获取点位节点
      const pathNodeList = document.getElementsByTagName('path')
      const gNodeList = document.getElementsByTagName('g')
      const deviceIds = resourceBeanList.map(x => x.deviceId)
      this.tagDeviceIds = resourceBeanList.map(x => x.deviceId.toString())
      this.formatSvgTaglable(resourceBeanList)

      // 旧版地图使用path
      if (pathNodeList.length > 0) {
        for (let i = 0; i < pathNodeList.length; i++) {
          const el = pathNodeList[i]
          let enableAdEventListener = false
          if (el.hasAttribute('data-id')) {
            // 判断是否允许购买
            if (el.getAttribute('fill') === '#008100') {
              el.setAttribute('isArrowChecked', true)
              enableAdEventListener = true
            } else {
              el.setAttribute('isArrowChecked', false)
            }

            // 判断灯箱点位是否在当前订单内被采购
            const deviceId = parseInt(el.getAttribute('data-id'))
            if (deviceIds.indexOf(deviceId) > -1) {
              el.setAttribute('fill', '#F54236') // 红色
              el.setAttribute('isSelected', true)
              enableAdEventListener = true
            } else {
              el.setAttribute('isSelected', false)
            }

            // 订单购买地图展示， 允许选择添加或者移除
            if (this.svgShowType === 2 && enableAdEventListener) {
              this.addElementPurchaseEvent(el)
            }
          }
        }
      }
      // 新版地图使用g
      if (gNodeList.length > 0) {
        for (let j = 0; j < gNodeList.length; j++) {
          const newEl = gNodeList[j]
          let enableAdEventListener = false
          if (newEl.hasAttribute('data-id')) {
            // 判断是否允许购买
            if (newEl.getAttribute('fill') === '#008100') {
              newEl.setAttribute('isArrowChecked', true)
              enableAdEventListener = true
            } else {
              newEl.setAttribute('isArrowChecked', false)
            }

            // // 判断灯箱点位是否在当前订单内被采购
            const deviceId = parseInt(newEl.getAttribute('data-id'))
            if (deviceIds.indexOf(deviceId) > -1) {
              newEl.setAttribute('fill', '#F54236') // 红色
              const gChild = newEl.children
              for (let k = 0; k < gChild.length; k++) {
                gChild[k].setAttribute('fill', '#F54236') // 红色
              }
              newEl.setAttribute('isSelected', true)
              enableAdEventListener = true
            } else {
              newEl.setAttribute('isSelected', false)
            }

            // 订单购买地图展示， 允许选择添加或者移除
            if (this.svgShowType === 2 && enableAdEventListener) {
              this.addElementPurchaseEvent(newEl)
            }
          }
        }
      }
    },
    addElementPurchaseEvent (element) {
      element.setAttribute('cursor', 'pointer')
      const _self = this
      const rId = element.getAttribute('data-id')

      // 绑定事件, 不能用addEventListener，否则多个tab切换，会重复绑定事件，并导致重复触发
      element.onclick = () => {
        _self.$emit('on-purchase', {
          isDeleted: element.getAttribute('isSelected') === 'true',
          deviceId: rId,
          checkSchedules: _self.searchSchedules,
          elementNodeName: element.nodeName
        })
      }
    },
    changeResourceColor (deviceId, isSelected, nodeName) {
      if (nodeName === 'path') {
        const pathNodeList = document.getElementsByTagName('path')
        for (let i = 0; i < pathNodeList.length; i++) {
          const el = pathNodeList[i]
          if (el.hasAttribute('data-id') && deviceId === el.getAttribute('data-id')) {
            el.setAttribute('fill', isSelected ? '#F54236' : '#008100')
            // 添加资源选中状态
            el.setAttribute('isSelected', isSelected)
            // 变更当前采购标识数字
            const currentTabIndex = parseInt(this.defaultTab.split('tab')[1])
            this.stationTabLables[currentTabIndex].quantity += isSelected ? 1 : -1
          }
        }
      } else {
        const gNodeList = document.getElementsByTagName('g')
        for (let i = 0; i < gNodeList.length; i++) {
          const el = gNodeList[i]
          if (el.hasAttribute('data-id') && deviceId === el.getAttribute('data-id')) {
            el.setAttribute('fill', isSelected ? '#F54236' : '#008100')
            const gChild = el.children
            for (let k = 0; k < gChild.length; k++) {
              if (gChild[k].nodeName === 'text' && gChild[k].getAttribute('type') === 'deviceCode') {
                // 显示出来的编号
              } else {
                gChild[k].setAttribute('fill', isSelected ? '#F54236' : '#008100') // 红色
              }
            }
            // 添加资源选中状态
            el.setAttribute('isSelected', isSelected)
            // 变更当前采购标识数字
            const currentTabIndex = parseInt(this.defaultTab.split('tab')[1])
            this.stationTabLables[currentTabIndex].quantity += isSelected ? 1 : -1
          }
        }
      }
    },
    initShowBrand () {
      if (this.searchSchedules.length === 0) { return }

      const queryBean = {
        stationIds: this.stationId,
        schedules: JSON.stringify(this.searchSchedules),
        orderId: this.orderId // 需要排除的订单ID
      }

      getSoldResourceInfo(queryBean).then(res => {
        if (res.length > 0) {
          // 循环格式化SVG，添加所有售出的品牌
          const pathNodeList = document.getElementsByTagName('path')
          res.forEach(element => {
            this.fillNodeClickEvent(pathNodeList, element)
          })

          // 新版本地图使用的G标签，标注媒体
          const gNodeList = document.getElementsByTagName('g')
          res.forEach(element => {
            this.fillNodeClickEvent(gNodeList, element, true)
          })
        }
      })
    },
    choseSpinShow () {
      this.spinShow = false
    },
    fillNodeClickEvent (nodeList, demandEntity, isNewMap = false) { // 填充已上刊点位的事件
      // 在点位中添加上刊品牌文本,  如果不显示品牌，那么就显示行业
      let screenName = ''
      const _self = this
      if (this.resourceShowBrand === 1) {
        screenName = demandEntity.brandName
      } else if (this.resourceShowBrand === 2) {
        screenName = demandEntity.industryName
      }

      if (nodeList.length > 0) {
        for (let i = 0; i < nodeList.length; i++) {
          const el = nodeList[i]
          if (el.hasAttribute('data-id')) {
            // 判断灯箱点位是否为上刊点位
            const deviceId = parseInt(el.getAttribute('data-id'))
            if (demandEntity.deviceId === deviceId) {
              el.setAttribute('cursor', 'pointer')
              // 电子屏不需要排队
              if (!['301', '306'].includes(el.getAttribute('data-devicemodelid'))) {
                el.onclick = () => {
                  _self.bindClickStationBrand(deviceId)
                }
              }

              // 在路劲内部添加上刊品牌
              if (isNewMap) { // 新版地图需要将文本添加到内部
                let elBBox = null
                const gChild = el.children
                for (let k = 0; k < gChild.length; k++) {
                  if (gChild[k].hasAttribute('x1')) {
                    elBBox = {
                      x: parseFloat(gChild[k].getAttribute('x1')),
                      y: parseFloat(gChild[k].getAttribute('y1'))
                    }
                    break
                  } else if (gChild[k].hasAttribute('x')) {
                    elBBox = {
                      x: parseFloat(gChild[k].getAttribute('x')),
                      y: parseFloat(gChild[k].getAttribute('y'))
                    }
                    break
                  } else if (gChild[k].nodeName === 'path') {
                    const pathBox = gChild[k].getAttribute('d').split('m')[1].split('c')[0].split(',')
                    elBBox = {
                      x: parseFloat(pathBox[0]),
                      y: parseFloat(pathBox[1])
                    }
                    break
                  }
                }

                if (!elBBox) {
                  elBBox = el.getBBox()
                }

                el.appendChild(this.fillPathText(screenName, deviceId, true, true, elBBox))
                if (screenName) { // 电子屏的上刊品牌为数组
                  el.setAttribute('brandFullName', screenName)
                }
              } else {
                el.after(this.fillPathText(screenName, deviceId))
              }
            }
          }
        }
      }
    },
    fillPathText (screenName, deviceId, isClick = true, isNewMap = false, elBBox = null) {
      const _self = this
      const text = document.createElementNS('http://www.w3.org/2000/svg', 'text')

      // 创建一个text，并添加到path后面
      text.setAttribute('fill', '#000')
      text.setAttribute('cursor', 'pointer')
      text.setAttribute('x', isNewMap ? elBBox.x : 0)
      if (this.$store.getters.token.userInfo.publisherId === 13) { // 青岛地铁的地图样式和厦门不一样
        text.setAttribute('font-size', isNewMap ? '4' : '8')
        text.setAttribute('y', isNewMap ? elBBox.y + 5 : 7)
      } else {
        text.setAttribute('font-size', isNewMap ? '4' : '14')
        text.setAttribute('y', isNewMap ? elBBox.y + 8 : 10)
      }
      text.textContent = screenName.substr(0, 6)
      text.onclick = () => {
        if (isClick) {
          // 添加排队
          _self.bindClickStationBrand(deviceId)
        } else {
          _self.$emit('on-select', deviceId)
        }
      }

      return text
    },
    bindClickStationBrand (deviceId) {
      this.$refs.resourceRecord.initResourceRecordData(deviceId, this.searchSchedules, this.orderId)
    },
    formatSvgTaglable (resourceBeanList) {
      const floorStatistic = []
      const distinctResource = []
      let floorStatisticBean

      resourceBeanList.forEach(element => {
        if (distinctResource.indexOf(element.deviceId) === -1) {
          distinctResource.push(element.deviceId)
          floorStatisticBean = floorStatistic.find(x => x.stationId === element.stationId && x.floor === element.floor)
          if (floorStatisticBean) {
            floorStatisticBean.quantity++
          } else {
            floorStatistic.push({
              stationId: element.stationId,
              floor: element.floor,
              quantity: 1
            })
          }
        }
      })

      // 开始重新格式化Tab标签
      let index = 0
      let floorStatisticBySvgHtml
      this.svgList.forEach(svgHtml => {
        floorStatisticBySvgHtml = floorStatistic.find(x => x.stationId === svgHtml.stationId && x.floor === svgHtml.floor)
        if (floorStatisticBySvgHtml) {
          this.stationTabLables[index].quantity = floorStatisticBySvgHtml.quantity
          if (this.defaultTab === 'tab0' && this.stationTabLables[0].quantity === 0 && floorStatisticBySvgHtml.quantity > 0) {
            this.defaultTab = 'tab' + index
            this.beginSvgPanZoom(this.svgId + '_' + index)
            this.changeTabs(this.defaultTab)
          }
        }
        index++
      })

      this.stationTabLables = Object.assign({}, this.stationTabLables)
    },
    stationLabel (index) {
      return (h) => {
        return h('div', [
          h('span', this.stationTabLables[index].label),
          h('Badge', {
            style: {
              left: '5px',
              top: '-10px'
            },
            props: {
              count: this.stationTabLables[index].quantity
            }
          })
        ])
      }
    },
    handleShowAllDemand () {
      this.$emit('svg-show-alldemand')
    },
    addMapMouseEvent (svgElement) { // 添加普通地图的事件
      const svgChidrenElement = svgElement.lastElementChild.lastElementChild.children
      const _self = this

      if (svgChidrenElement) {
        for (let index = 0; index < svgElement.lastElementChild.children.length; index++) {
          // 移除title标签， 改标签用于tips
          if (svgElement.lastElementChild.children[index].nodeName === 'title') {
            svgElement.lastElementChild.removeChild(svgElement.lastElementChild.children[index])
          } else if (svgElement.lastElementChild.children[index].children[0].nodeName === 'title') {
            svgElement.lastElementChild.children[index].removeChild(svgElement.lastElementChild.children[index].children[0])
          }
        }

        for (let index = 0; index < svgChidrenElement.length; index++) {
          if (svgChidrenElement[index].nodeName === 'g' && svgChidrenElement[index].hasAttribute('data-id')) {
            // 为媒体元素添加鼠标事件
            const curElement = svgChidrenElement[index]
            const xOffset = 15
            const yOffset = 15
            // 某些地图显示方式不需要有鼠标点击事件，可在需要点击的地方添加此鼠标样式
            // curElement.setAttribute('cursor', 'pointer')

            // 将所有的墙贴边框线条加粗，不然很难 被选中 和 区分底图
            // if (curElement.getAttribute('data-devicemodelid') === '110') {
            //   for (let h = 0; h < curElement.children.length; h++) {
            //     // 异性墙贴可能包含有多个G组成的内容, 循环检查G标签内的元素
            //     if (curElement.children[h].nodeName === 'g') {
            //       for (let gIndex = 0; gIndex < curElement.children[h].children.length; gIndex++) {
            //         curElement.children[h].children[gIndex].setAttribute('stroke-width', '2')
            //         // curElement.children[h].children[gIndex].setAttribute('stroke', '#231814')
            //       }
            //     } else {
            //       curElement.children[h].setAttribute('stroke-width', '2')
            //     }
            //   }
            // }

            // 添加鼠标进入事件
            curElement.addEventListener('mouseover', (e) => {
              for (let i = 0; i < curElement.children.length; i++) {
                if (curElement.getAttribute('data-devicemodelid') === '110') {
                  if (curElement.children[i].nodeName === 'g') {
                  // 异性墙贴可能包含有多个G组成的内容, 循环检查G标签内的元素
                    for (let gIndex = 0; gIndex < curElement.children[i].children.length; gIndex++) {
                      // 如果当前元素包含边框元素的话
                      if (curElement.children[i].children[gIndex].hasAttribute('stroke')) {
                        curElement.children[i].children[gIndex].setAttribute('stroke', '#19be6b')
                      }
                    }
                  } else if (curElement.children[i].nodeName !== 'text' && curElement.children[i].hasAttribute('stroke')) {
                    curElement.children[i].setAttribute('stroke', '#19be6b')
                  }
                } else if (curElement.children[i].nodeName !== 'text' && curElement.children[i].hasAttribute('stroke') && curElement.children[i].getAttribute('stroke') !== 'null') {
                  curElement.children[i].setAttribute('stroke', '#19be6b')
                }
              }

              var divTemp = document.createElement('div')
              var nodes = null
              // 文档片段，一次性append，提高性能
              var fragment = document.createDocumentFragment()
              // 如果包含上刊品牌，那么显示完整品牌信息
              if (curElement.hasAttribute('brandFullName')) {
                divTemp.innerHTML = "<div id='screenshot'><p>" + curElement.getAttribute('data-devicemodel') + '</p><p>' + curElement.getAttribute('data-devicecode') + '</p><p>上刊品牌：' + curElement.getAttribute('brandFullName') + '</p></div>'
              } else {
                divTemp.innerHTML = "<div id='screenshot'><p>" + curElement.getAttribute('data-devicemodel') + '</p><p>' + curElement.getAttribute('data-devicecode') + '</p></div>'
              }

              nodes = divTemp.childNodes
              for (var i = 0, length = nodes.length; i < length; i += 1) {
                fragment.appendChild(nodes[i].cloneNode(true))
              }
              svgElement.parentNode.appendChild(fragment)
              // 据说下面这样子世界会更清净
              nodes = null
              fragment = null

              document.getElementById('screenshot').style.top = (e.offsetY + yOffset) + 'px'
              document.getElementById('screenshot').style.left = (e.offsetX + xOffset) + 'px'
            })
            // 添加鼠标移出事件
            curElement.addEventListener('mouseout', (e) => {
              for (let j = 0; j < curElement.children.length; j++) {
                if (curElement.getAttribute('data-devicemodelid') === '110') {
                  if (curElement.children[j].nodeName === 'g') {
                  // 异性墙贴可能包含有多个G组成的内容, 循环检查G标签内的元素
                    for (let gIndex = 0; gIndex < curElement.children[j].children.length; gIndex++) {
                      // 如果当前元素包含边框元素的话
                      if (curElement.children[j].children[gIndex].hasAttribute('stroke')) {
                        curElement.children[j].children[gIndex].setAttribute('stroke', _self.tagDeviceIds.includes(curElement.getAttribute('data-id')) ? '#F54236' : '#231814')
                      }
                    }
                  } else if (curElement.children[j].nodeName !== 'text' && curElement.children[j].hasAttribute('stroke')) {
                    curElement.children[j].setAttribute('stroke', _self.tagDeviceIds.includes(curElement.getAttribute('data-id')) ? '#F54236' : '#231814')
                  }
                } else if (curElement.children[j].nodeName !== 'text' && curElement.children[j].hasAttribute('stroke') && curElement.children[j].getAttribute('stroke') !== 'null') {
                  curElement.children[j].setAttribute('stroke', '#000000')
                }
              }

              document.getElementById('screenshot').parentNode.removeChild(document.getElementById('screenshot'))
            })
            curElement.addEventListener('mousemove', (e) => {
              document.getElementById('screenshot').style.top = (e.offsetY + yOffset) + 'px'
              document.getElementById('screenshot').style.left = (e.offsetX + xOffset) + 'px'
            })
          } else if (svgChidrenElement[index].nodeName === 'title') {
            svgElement.lastElementChild.lastElementChild.removeChild(svgChidrenElement[index])
          }
        }
      }
    },
    handleShowOtherWorkItem () {
      this.$emit('handleShowOtherWorkItem')
    },
    handleCheckIcon (iconDeviceModels) {
      this.chooseIcon = (this.chooseIcon === iconDeviceModels) ? null : iconDeviceModels

      const currentSvgId = this.svgId + '_' + this.defaultTab.substr(3)
      // 确认需要显示的ICON
      const gNodeList = document.getElementById(currentSvgId).getElementsByTagName('g')
      if (gNodeList.length > 0) {
        for (let j = 0; j < gNodeList.length; j++) {
          const newEl = gNodeList[j]

          if (newEl.hasAttribute('data-devicemodelid')) {
            // 双面电子屏306，需要兼容到301的电子屏
            if (this.chooseIcon === '301' && ['301', '306'].includes(newEl.getAttribute('data-devicemodelid'))) {
              newEl.removeAttribute('display')
            } else if (newEl.getAttribute('data-devicemodelid') === this.chooseIcon || this.chooseIcon === null) {
              newEl.removeAttribute('display')
            } else {
              newEl.setAttribute('display', 'none')
            }
          }
        }
      }
    },
    handleDownloadMap (index) {
      this.spinShow = true
      const fileName = this.stationTabLables[index].stationName + '(' + this.stationTabLables[index].label + ').png'

      // 设置需要下载的原图
      const downloadElement = document.getElementById('download-svg-' + this.svgId)
      downloadElement.innerHTML = document.getElementById(this.svgId + '_' + index).innerHTML
      this.$nextTick(() => {
        const currMap = svgPanZoom('#download-svg-' + this.svgId + ' svg')
        currMap.zoomAtPointBy(4, { x: 0, y: -60 })
        // 添加档期和备注信息
        this.addMapScheduleHelper(downloadElement.firstElementChild.lastElementChild)
        // 开始往地图中添加图例标识
        this.addMapIcon(downloadElement.firstElementChild.lastElementChild)

        const that = this
        setTimeout(function () {
          that.spinShow = false
          const postData = {
            svg: HTMLEncode(downloadElement.innerHTML)
          }
          downloadFilePostRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-draw/svg/downloadsvgpng', postData, fileName)
          downloadElement.innerHTML = ''
        }, 1000)
      })
    },
    addMapScheduleHelper (masterElement) {
      // 开始往地图中添加图例标识
      // const masterElement = document.getElementById(this.svgId + '_' + index).firstElementChild.lastElementChild
      const groupElement = document.createElementNS('http://www.w3.org/2000/svg', 'g')
      groupElement.setAttribute('transform', 'translate(4000 50) scale(5)')
      masterElement.after(groupElement)
      let startX = 0
      if (this.svgShowType === 4) {
        startX = 205

        const text1 = document.createElementNS('http://www.w3.org/2000/svg', 'text')
        text1.setAttribute('fill', '#000')
        text1.setAttribute('font-size', '12')
        text1.setAttribute('x', '55')
        text1.setAttribute('y', '10')
        text1.textContent = '不可用'
        const text2 = document.createElementNS('http://www.w3.org/2000/svg', 'text')
        text2.setAttribute('fill', '#000')
        text2.setAttribute('font-size', '12')
        text2.setAttribute('x', '165')
        text2.setAttribute('y', '10')
        text2.textContent = '其他灯箱点位'
        const text3 = document.createElementNS('http://www.w3.org/2000/svg', 'text')
        text3.setAttribute('fill', '#000')
        text3.setAttribute('font-size', '12')
        text3.setAttribute('x', '305')
        text3.setAttribute('y', '10')
        text3.textContent = '当前在刊 / 作业点位'

        const rect1 = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
        rect1.setAttribute('fill', '#B6B6B7')
        rect1.setAttribute('stroke', '#fff')
        rect1.setAttribute('width', '50')
        rect1.setAttribute('height', '12')
        rect1.setAttribute('x', '0')
        const rect2 = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
        rect2.setAttribute('fill', '#008100')
        rect2.setAttribute('stroke', '#fff')
        rect2.setAttribute('width', '50')
        rect2.setAttribute('height', '12')
        rect2.setAttribute('x', '110')
        const rect3 = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
        rect3.setAttribute('fill', '#F54236')
        rect3.setAttribute('stroke', '#fff')
        rect3.setAttribute('width', '50')
        rect3.setAttribute('height', '12')
        rect3.setAttribute('x', '250')

        groupElement.appendChild(rect1)
        groupElement.appendChild(text1)
        groupElement.appendChild(rect2)
        groupElement.appendChild(text2)
        groupElement.appendChild(rect3)
        groupElement.appendChild(text3)
      } else if (this.svgShowType === 5) {
        const text1 = document.createElementNS('http://www.w3.org/2000/svg', 'text')
        text1.setAttribute('fill', '#000')
        text1.setAttribute('font-size', '12')
        text1.setAttribute('x', '55')
        text1.setAttribute('y', '10')
        text1.textContent = '不可用'
        const text2 = document.createElementNS('http://www.w3.org/2000/svg', 'text')
        text2.setAttribute('fill', '#000')
        text2.setAttribute('font-size', '12')
        text2.setAttribute('x', '165')
        text2.setAttribute('y', '10')
        text2.textContent = '待处理'

        const rect1 = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
        rect1.setAttribute('fill', '#B6B6B7')
        rect1.setAttribute('stroke', '#fff')
        rect1.setAttribute('width', '50')
        rect1.setAttribute('height', '12')
        rect1.setAttribute('x', '0')
        const rect2 = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
        rect2.setAttribute('fill', '#F54236')
        rect2.setAttribute('stroke', '#fff')
        rect2.setAttribute('width', '50')
        rect2.setAttribute('height', '12')
        rect2.setAttribute('x', '110')

        groupElement.appendChild(rect1)
        groupElement.appendChild(text1)
        groupElement.appendChild(rect2)
        groupElement.appendChild(text2)
      } else if (this.svgShowType === 6) {
        startX = 135

        const text1 = document.createElementNS('http://www.w3.org/2000/svg', 'text')
        text1.setAttribute('fill', '#000')
        text1.setAttribute('font-size', '12')
        text1.setAttribute('x', '55')
        text1.setAttribute('y', '10')
        text1.textContent = '不可用'
        const text2 = document.createElementNS('http://www.w3.org/2000/svg', 'text')
        text2.setAttribute('fill', '#000')
        text2.setAttribute('font-size', '12')
        text2.setAttribute('x', '165')
        text2.setAttribute('y', '10')
        text2.textContent = '无任务媒体'
        const text3 = document.createElementNS('http://www.w3.org/2000/svg', 'text')
        text3.setAttribute('fill', '#000')
        text3.setAttribute('font-size', '12')
        text3.setAttribute('x', '295')
        text3.setAttribute('y', '10')
        text3.textContent = '作业媒体'

        const rect1 = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
        rect1.setAttribute('fill', '#B6B6B7')
        rect1.setAttribute('stroke', '#fff')
        rect1.setAttribute('width', '50')
        rect1.setAttribute('height', '12')
        rect1.setAttribute('x', '0')
        const rect2 = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
        rect2.setAttribute('fill', '#008100')
        rect2.setAttribute('stroke', '#fff')
        rect2.setAttribute('width', '50')
        rect2.setAttribute('height', '12')
        rect2.setAttribute('x', '110')
        const rect3 = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
        rect3.setAttribute('fill', '#F54236')
        rect3.setAttribute('stroke', '#fff')
        rect3.setAttribute('width', '50')
        rect3.setAttribute('height', '12')
        rect3.setAttribute('x', '240')

        groupElement.appendChild(rect1)
        groupElement.appendChild(text1)
        groupElement.appendChild(rect2)
        groupElement.appendChild(text2)
        groupElement.appendChild(rect3)
        groupElement.appendChild(text3)
      } else {
        startX = 230
        const text1 = document.createElementNS('http://www.w3.org/2000/svg', 'text')
        text1.setAttribute('fill', '#000')
        text1.setAttribute('font-size', '12')
        text1.setAttribute('x', '55')
        text1.setAttribute('y', '10')
        text1.textContent = '不可用'
        const text2 = document.createElementNS('http://www.w3.org/2000/svg', 'text')
        text2.setAttribute('fill', '#000')
        text2.setAttribute('font-size', '12')
        text2.setAttribute('x', '165')
        text2.setAttribute('y', '10')
        text2.textContent = '已售出'
        const text3 = document.createElementNS('http://www.w3.org/2000/svg', 'text')
        text3.setAttribute('fill', '#000')
        text3.setAttribute('font-size', '12')
        text3.setAttribute('x', '275')
        text3.setAttribute('y', '10')
        text3.textContent = '可售卖'
        const text4 = document.createElementNS('http://www.w3.org/2000/svg', 'text')
        text4.setAttribute('fill', '#000')
        text4.setAttribute('font-size', '12')
        text4.setAttribute('x', '385')
        text4.setAttribute('y', '10')
        text4.textContent = '当前已选中'

        const rect1 = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
        rect1.setAttribute('fill', '#B6B6B7')
        rect1.setAttribute('stroke', '#fff')
        rect1.setAttribute('width', '50')
        rect1.setAttribute('height', '12')
        rect1.setAttribute('x', '0')
        const rect2 = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
        rect2.setAttribute('fill', '#FFEB3A')
        rect2.setAttribute('stroke', '#fff')
        rect2.setAttribute('width', '50')
        rect2.setAttribute('height', '12')
        rect2.setAttribute('x', '110')
        const rect3 = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
        rect3.setAttribute('fill', '#008100')
        rect3.setAttribute('stroke', '#fff')
        rect3.setAttribute('width', '50')
        rect3.setAttribute('height', '12')
        rect3.setAttribute('x', '220')
        const rect4 = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
        rect4.setAttribute('fill', '#F54236')
        rect4.setAttribute('stroke', '#fff')
        rect4.setAttribute('width', '50')
        rect4.setAttribute('height', '12')
        rect4.setAttribute('x', '330')

        groupElement.appendChild(rect1)
        groupElement.appendChild(text1)
        groupElement.appendChild(rect2)
        groupElement.appendChild(text2)
        groupElement.appendChild(rect3)
        groupElement.appendChild(text3)
        groupElement.appendChild(rect4)
        groupElement.appendChild(text4)
      }

      // 开始添加档期
      if (this.searchSchedules.length > 0) {
        const textLabel = document.createElementNS('http://www.w3.org/2000/svg', 'text')
        textLabel.setAttribute('fill', '#000')
        textLabel.setAttribute('font-size', '12')
        textLabel.setAttribute('x', startX)
        textLabel.setAttribute('y', '35')
        textLabel.textContent = '查询档期：'
        groupElement.appendChild(textLabel)

        let flagIndex = 0
        this.searchSchedules.forEach(element => {
          const textSchedule = document.createElementNS('http://www.w3.org/2000/svg', 'text')
          textSchedule.setAttribute('fill', '#000')
          textSchedule.setAttribute('font-size', '12')
          textSchedule.setAttribute('x', startX + 65)
          textSchedule.setAttribute('y', 35 + (flagIndex * 20))
          textSchedule.textContent = element.startDate + ' 至 ' + element.endDate
          groupElement.appendChild(textSchedule)
          flagIndex++
        })
      }
    },
    addMapIcon (masterElement) {
      const iconNumber = this.showButtonIcons.length

      const groupElement = document.createElementNS('http://www.w3.org/2000/svg', 'g')
      groupElement.setAttribute('transform', 'translate(4000 ' + (iconNumber > 5 ? '3500' : '3600') + ') scale(5)')
      masterElement.after(groupElement)

      // 创建边框, 根据ICON数量来计算高度，每行不超过5个图例
      const iconRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
      iconRect.setAttribute('stroke', '#dcdee2')
      iconRect.setAttribute('fill', '#fff')
      iconRect.setAttribute('width', '400')
      iconRect.setAttribute('height', iconNumber > 5 ? '100' : '50')
      iconRect.setAttribute('x', '0')
      groupElement.appendChild(iconRect)

      // 创建文本
      const textLabel = document.createElementNS('http://www.w3.org/2000/svg', 'text')
      textLabel.setAttribute('fill', '#000')
      textLabel.setAttribute('font-size', '12')
      textLabel.setAttribute('x', '10')
      textLabel.setAttribute('y', '20')
      textLabel.textContent = '图例：'
      groupElement.appendChild(textLabel)

      // 添加ICON
      let iconStartX = 0
      let iconStartY = 0

      for (let index = 0; index < this.showButtonIcons.length; index++) {
        iconStartX = 50 + (index * 70)
        iconStartY = index > 5 ? 65 : 15

        // ICON的SVG代码
        const iconSvg = document.createElementNS('http://www.w3.org/2000/svg', 'g')
        if (this.showButtonIcons[index].deviceModels === '605') {
          iconSvg.setAttribute('transform', 'translate(' + iconStartX + ' ' + (iconStartY - 10) + ')')
        } else {
          iconSvg.setAttribute('transform', 'translate(' + iconStartX + ' ' + iconStartY + ')')
        }
        iconSvg.innerHTML = this.showButtonIcons[index].svg
        groupElement.appendChild(iconSvg)

        // ICON的描述
        const iconLabel = document.createElementNS('http://www.w3.org/2000/svg', 'text')
        iconLabel.setAttribute('fill', '#808695')
        iconLabel.setAttribute('font-size', '10')
        iconLabel.setAttribute('x', iconStartX)
        iconLabel.setAttribute('y', iconStartY + 25)
        iconLabel.textContent = this.showButtonIcons[index].title.substr(0, 7)
        groupElement.appendChild(iconLabel)
      }
    },
    changeShowType () {
      const currentSvgId = this.svgId + '_' + this.defaultTab.substr(3)
      const currentSvgElement = document.getElementById(currentSvgId).firstElementChild
      const svgChidrenElement = currentSvgElement.lastElementChild.lastElementChild.children
      let elementBBox = null
      let elementX = null
      let elementY = null

      if (svgChidrenElement) {
        for (let index = 0; index < svgChidrenElement.length; index++) {
          if (svgChidrenElement[index].hasAttribute('data-id') && svgChidrenElement[index].childNodes.length > 0) {
            // 将资源CODE显示在媒体下方
            const curElement = svgChidrenElement[index]
            const deviceCode = curElement.getAttribute('data-devicecode')
            const deviceModelId = curElement.getAttribute('data-devicemodelid')
            // 移除括号内的值
            let formatDeviceCode = deviceCode
            if (deviceCode.includes('(') && deviceCode.includes(')')) {
              formatDeviceCode = deviceCode.split('(')[0] + deviceCode.split(')')[1]
            }
            const subDeviceCode = formatDeviceCode.split('-')
            let realLable = subDeviceCode[subDeviceCode.length - 1] // 真实显示出来的编号
            let startX = 9

            if (deviceModelId === '143') { // 单边梯牌需要单独处理， 例：BS03-19
              realLable = subDeviceCode[subDeviceCode.length - 2] + '-' + subDeviceCode[subDeviceCode.length - 1]
              startX = 10
            } else if (deviceModelId === '106') { // 梯牌， 只显示数字，例：06
              realLable = realLable.substr(2)
              startX = 2
            } else if (deviceModelId === '114') {
              startX = 3
            }

            if (this.switchShowType && curElement.lastElementChild.getAttribute('type') !== 'deviceCode') { // 显示出设备CODE
              // 判断是否已经自动添加了编号， 如果有。 那么不再添加
              for (let cindex = 0; cindex < curElement.children.length; cindex++) {
                if (curElement.children[cindex].nodeName === 'text' && curElement.children[cindex].textContent === realLable) {
                  // 退出循环
                  return
                }
              }

              // 如果当前元素执行过svg的transform-matrix
              const style = window.getComputedStyle(curElement)
              elementBBox = curElement.getBBox()
              // 可能是宽高被颠倒了
              if (elementBBox.x < 0 || elementBBox.y < 0) {
                elementX = elementBBox.width
                elementY = elementBBox.height
              } else {
                elementX = elementBBox.x
                elementY = elementBBox.y
              }
              // 创建一个text，并添加到path后面
              const text = document.createElementNS('http://www.w3.org/2000/svg', 'text')

              if (style.transform && style.transform.includes('matrix')) {
                const matrixValues = style.transform.match(/matrix.*\((.+)\)/)[1].split(', ')
                const a = matrixValues[0]
                const b = matrixValues[1]
                const scale = Math.sqrt(a * a + b * b)

                text.setAttribute('font-size', 5 / scale)
                text.setAttribute('x', elementX + startX)
              } else {
                text.setAttribute('font-size', 5)
                text.setAttribute('x', elementX + startX + 2)
              }

              text.setAttribute('fill', '#000')
              text.setAttribute('y', elementY - 2)
              text.setAttribute('type', 'deviceCode')
              // text.setAttribute('font-weight', 'bold')
              text.setAttribute('font-style', 'normal')
              text.setAttribute('text-anchor', 'middle')
              text.setAttribute('text-decoration', 'blink')
              text.setAttribute('font-family', '微软雅黑,黑体,Arial,宋体')

              text.textContent = realLable
              curElement.appendChild(text)
            } else if (!this.switchShowType) { // 移除设备CODE
              for (let j = 0; j < curElement.children.length; j++) {
                if (curElement.children[j].hasAttribute('type') && curElement.children[j].getAttribute('type') === 'deviceCode') {
                  curElement.removeChild(curElement.children[j])
                }
              }
            }
          }
        }
      }

      this.isSvgLoadingFisished = true
    },
    handleChangeMode () {
      this.$emit('handleChange3dMode', this.stationId)
      this.isShow = false
    },
    check3D () {
      check3d({ stationIds: this.stationId }).then(res => {
        this.isShowChange3D = res
      })
    },
    svgLoadingFisished () {
      if (this.isSvgLoadingFisished) {
        this.$emit('svg-finished')
      } else {
        const _self = this
        setTimeout(function () {
          _self.svgLoadingFisished()
        }, 500)
      }
    }
  },
  computed: {
    purchaseSchedules () {
      let showLable = ''
      this.searchSchedules.forEach(element => {
        if (element.label) {
          showLable += element.label + ' '
        } else {
          showLable += GetCurrentSchedule(element.startDate, element.endDate) + ' '
        }
      })

      return showLable
    }
  },
  watch: {
    isShow (val) {
      this.$emit('update:stationSvgModal', val)
    },
    stationSvgModal (val) {
      this.isShow = val
      if (val) {
        this.defaultTab = 'tab0'
        this.svgList = []
        this.svgId = newGuid()
        this.initPageData()
      }
    }
  }
}
</script>
